import { css } from '@emotion/react';
import { useTheme } from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';

const useStyles = () => {
  const theme = useTheme();

  return {
    root: css`
      width: 100%;
      height: fit-content;
      top: 0;
      left: 0;
      z-index: 999;
      background-color: ${theme.palette.background.paper};
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.24);
      ${theme.breakpoints.down('md')} {
        height: 60px;
      }
    `,
    saveForLaterContainer: css`
      padding-right: 37px;
      ${theme.breakpoints.down('md')} {
        padding-right: 30px;
      }
    `,
    additionalLinksContainer: css`
      margin-right: 30px;
      margin-top: 20px;
      ${theme.breakpoints.down('md')} {
        margin-right: 22px;
        margin-top: 10px;
      }
    `,
    container: css`
      height: 123px;
      width: 100%;

      ${theme.breakpoints.down('md')} {
        height: 60px;
      }
    `,
    logo: css`
      height: 50px;
      float: left;
      padding: 5px;
      margin: 35px 8px 35px 30px;
      ${theme.breakpoints.down('md')} {
        height: 20px;
        padding: 0px;
        margin: 20px 20px 20px 16px;
      }
    `,
    titleSection: css`
      height: 37px;
      margin-top: 48px;
      border-left: 1px solid #717073;
      float: left;
      margin-left: 1px;
    `,
    title: css`
      ${theme.breakpoints.up('md')} {
        line-height: 1;
      }
      margin-top: 5px;
      padding-left: 10px;
    `,
    needHelpContainer: css`
      cursor: pointer;
      ${theme.breakpoints.up('md')} {
        @media print: {
          display: none;
        },
      }
    `,
    iconText: css`
      font-weight: ${theme.typography.fontWeightMedium};
      margin-right: 10px;
      display: inline;
      line-height: 1;
    `,
    iconContainer: css`
      align-items: center;
      display: inline-flex;
      text-decoration: none;
      color: ${theme.palette.primary.main};
    `,
    helpMenu: css`
      position: absolute;
      top: 120px;
      right: 1%;
      z-index: ${theme.zIndex.tooltip};

      ${theme.breakpoints.down('md')} {
        position: absolute;
        top: 60px;
        right: 1%;
        z-index: theme.zIndex.tooltip;
      }
    `,
    disappearIconText: css`
      ${theme.breakpoints.down('md')} {
        display: none;
      }
    `,
    banner: css`
      background-color: ${theme.palette.secondary.main};
      height: 2px;
      position: relative;
      z-index: 0;

      ${theme.breakpoints.down('lg')} {
        width: 100%;
        z-index: 1000;
      }
    `,
    [theme.breakpoints.down('md')]: {
      helpMenu: {},
      title: {},
      additionalLinksContainer: {},
      needHelpContainer: {},
    },
    // Adjust additional styles as needed...
  };
};

export default useStyles;
