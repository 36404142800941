import Step from '@mui/material/Step';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import CoreStepper from '@mui/material/Stepper';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { useProgressStepperFlowModules } from '../../../app/ContextAPI';
import StepIcon from './StepIcon';
import useStyles from './useStyles';

const MobileStepper: React.FC = () => {
  const flowModules = useProgressStepperFlowModules();
  const location = useLocation();
  const activeModule = flowModules.findIndex((module) =>
    module.routes.includes(location.pathname),
  );
  const classes = useStyles();
  const theme = useTheme();
  const steps = useMemo(() => {
    return flowModules
      .map((module) => {
        // Filters the available pages to ensure they are enabled in the flow and not set to hidden.
        const displayablePages = module.pages.filter(
          (page) => page.enabled && !page.hidden,
        );
        if (displayablePages.length === 0) {
          return undefined;
        }

        // Create the step to be displayed under the module name in the stepper.
        const step = (
          <Step key={module.label}>
            <StepLabel StepIconComponent={StepIcon} css={classes.stepPage} />
          </Step>
        );
        return step;
      })
      .filter((step) => step);
  }, [classes.stepPage, flowModules]);

  return (
    <CoreStepper
      alternativeLabel
      activeStep={activeModule}
      connector={
        <StepConnector
          sx={{
            '& .Mui-active': {
              [`& .${stepConnectorClasses.line}`]: {},
            },
            '& .Mui-completed': {
              [`& .${stepConnectorClasses.line}`]: {},
            },
            '&.MuiStepConnector-root ': {
              left: 'calc(-50% + 6px) !important',
              right: 'calc(50% + 1px) !important',
              zIndex: '-1',
            },
            '& .MuiStepConnector-line': {
              paddingRight: '10px',
              borderStyle: 'dashed',
              borderWidth: '1px',
              borderRadius: '1px',
              fontWeight: 'bold',
              marginTop: '5px',
              borderColor: theme.palette.primary.main,
            },
          }}
        />
      }
      css={classes.root}
      orientation="horizontal"
    >
      {steps}
    </CoreStepper>
  );
};

export default MobileStepper;
