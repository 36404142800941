import { css } from '@emotion/react';
import { useTheme } from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';

const useStyles = () => {
  const theme = useTheme();

  return {
    root: css`
      ${theme.breakpoints.up('md')} {
        z-index: 1000;
        position: relative;
        max-width: 100%;
        flex-basis: 100%;
      }

      @media print {
        display: none;
      }
    `,
  };
};

export default useStyles;
