import { useTheme } from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';
import { css } from '@emotion/react';

const useStyles = () => {
  const theme = useTheme();

  return {
    root: css`
      @media (max-width: 960px) {
        padding: 20px;
      }
    `,
    image: css`
      width: 90%;
      height: 732px;
      margin-top: 72px;

      @media (max-width: 960px) {
        width: 75%;
        position: relative;
        left: 10%;
        object-fit: cover;
        height: 296px;
        margin: auto;
        margin-top: 15px;
      }
    `,
    titleContainer: css`
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
    `,
    title: css`
      text-transform: capitalize;

      @media (min-width: 960px) {
        margin-top: 90px;
      }
    `,
    subtitle: css`
      margin-top: 25px;
      margin-bottom: 48px;

      @media (max-width: 960px) {
        margin-top: 10px;
        margin-bottom: 15px;
      }
    `,
    footerSeparation: css`
      margin-top: 20px;
    `,
    // Example of using theme values
    footerColor: css`
      color: ${theme.palette.secondary.main};
    `,
  };
};

export default useStyles;
