import Grid from '@mui/material/Grid';
import isEmpty from 'lodash/isEmpty';
import type { ProductQuoteId } from '../../../app/ContextAPI';
import useStyles from './useStyles';

export interface ProductQuoteIdsProps {
  productQuoteIds: ProductQuoteId[];
}

const ProductQuoteIds: React.FC<ProductQuoteIdsProps> = ({
  productQuoteIds,
}) => {
  const classes = useStyles();

  if (isEmpty(productQuoteIds)) {
    return null;
  }

  return (
    <div css={classes.border}>
      <div css={classes.root}>
        <div css={classes.title}>Your Quote IDs:</div>
        {productQuoteIds.map((productQuoteId) => {
          const { productCode, label, quoteId } = productQuoteId;
          return (
            <Grid
              key={productCode}
              container
              direction="column"
              css={classes.container}
            >
              <Grid item xs={6} container>
                {label}
              </Grid>
              <Grid item id={`${productCode}-quoteId`} css={classes.value}>
                {quoteId || 'Not Started Yet'}
              </Grid>
            </Grid>
          );
        })}
      </div>
    </div>
  );
};

export default ProductQuoteIds;
