import { useLoadingStateProcess } from '../../app/ContextAPI';
import DefaultSpinner from './DefaultSpinner';

export interface LoadingSpinnerProps {
  message?: React.ReactNode;
  isLoading?: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  message: messageProp,
  isLoading: isLoadingProp,
}) => {
  const currentProcess = useLoadingStateProcess();
  const isLoading = currentProcess ? true : isLoadingProp;
  const message = currentProcess ? currentProcess.message : messageProp;

  return <DefaultSpinner message={message} isLoading={isLoading} />;
};

export default LoadingSpinner;
