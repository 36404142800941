import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { useDisplayProgressStepper } from '../../app/ContextAPI';
import MobileAppBarNew from './MobileAppBarNew';
import Stepper from './Stepper';
import useStyles from './useStyles';

const ProgressStepper: React.FC = () => {
  const displayProgressStepper = useDisplayProgressStepper();
  const classes = useStyles();

  return displayProgressStepper ? (
    <>
      <Hidden lgDown>
        <Grid container>
          <Grid item lg={2} css={classes.root}>
            <Stepper />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <MobileAppBarNew />
      </Hidden>
    </>
  ) : null;
};

export default ProgressStepper;
