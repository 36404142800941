import { css } from '@emotion/react';

export const size = 60;

const useStyles = () => {
  return {
    outerCircle: css`
      margin-left: -${size}px;
    `,
  };
};

export default useStyles;
