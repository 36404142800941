import { css } from '@emotion/react';
import { useTheme } from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';

const useStyles = () => {
  const theme = useTheme();

  return {
    callCenterBox: css`
      min-height: 210px;
    `,
    fixed: css`
      ${theme.breakpoints.up('lg')} {
        position: fixed;
        top: 25px;
        right: 0;
      }
    `,
    locked: css`
      ${theme.breakpoints.up('lg')} {
        position: absolute;
        overflow: hidden;
        right: 0;
      }
    `,
    cardStyling: css`
      padding-right: 20px;
      padding-bottom: 20px;
      ${theme.breakpoints.down('lg')} {
        padding-left: 18px;
        padding-top: 25px;
      }
    `,
  };
};

export default useStyles;
