import DisplayText from '@amfament/digitalsales-lib-coreuicomps/DisplayText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import CoreCircularProgress from '@mui/material/CircularProgress';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { useProgressStepperFlowModules } from '../../../../../app/ContextAPI';
import useStyles, { size } from './useStyles';

const CircularProgress: React.FC = () => {
  const flowModules = useProgressStepperFlowModules();
  const classes = useStyles();
  const theme = useTheme();

  const allRoutes = useMemo(
    () =>
      isEmpty(flowModules)
        ? []
        : flowModules
            .map((module) => module.routesWithoutHidden)
            .reduce((primeRoutes, routes) => primeRoutes.concat(routes)),
    [flowModules],
  );

  const location = useLocation();
  const activePage = allRoutes.indexOf(location.pathname);

  const percentFinished = Math.round(
    ((activePage + 1) / allRoutes.length) * 100,
  );
  const innerCircleRotation = Math.round(percentFinished * 1.8 + 45);

  return (
    <Box position="relative" display="inline-flex">
      <svg width="0" height={`${size}`}>
        <linearGradient id="progressInnerGradient" x1="0" y1="0" x2="1" y2="1">
          <stop
            offset={`${percentFinished}%`}
            stopColor="rgb(127,161,213)"
            stopOpacity="0.00"
          />
          <stop offset="100%" stopColor="rgb(127,161,213)" stopOpacity="1.00" />
        </linearGradient>
      </svg>
      <CoreCircularProgress
        variant="determinate"
        value={100}
        sx={{
          '& .MuiCircularProgress-circle': {
            stroke: 'url(#progressInnerGradient)',
          },
        }}
        size={size}
        style={{ transform: `rotate(${innerCircleRotation}deg)` }}
        aria-label="initial circular progress bar"
      />
      <CoreCircularProgress
        css={classes.outerCircle}
        variant="determinate"
        value={percentFinished}
        size={size}
        aria-label="circular progress bar"
      />

      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {percentFinished >= 100 ? (
          <CheckCircleIcon
            htmlColor={theme.palette.success.light}
            fontSize="medium"
          />
        ) : (
          <DisplayText
            variant="h3"
            component="div"
            color="primary.main"
          >{`${percentFinished}%`}</DisplayText>
        )}
      </Box>
    </Box>
  );
};

export default CircularProgress;
