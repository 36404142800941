import { css } from '@emotion/react';
import { useTheme } from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';

const useStyles = () => {
  const theme = useTheme();

  return {
    root: css`
      height: 100%;
      width: 100%;
      bottom: 0;

      @media print {
        display: none;
      }
    `,
    containerColor: css`
      border-top: 2px solid ${theme.palette.grey[200]};
      width: 100%;
      background-color: ${theme.palette.grey[100]};
    `,
    container: css`
      padding: 30px;
      margin: auto;
      max-width: 768px;
      min-width: 40%;
    `,
    containerWide: css`
      padding: 30px;
      margin: auto;
      width: 100%;
      max-width: 1056px;
    `,
    copyright: css`
      font-size: 0.75rem;
      color: #717073;
      text-align: center;
      margin-top: 10px;

      ${theme.breakpoints.down('md')} {
        font-size: 0.6875rem;
      }
    `,
    mobileSpacing: css`
      text-align: center;

      ${theme.breakpoints.down('md')} {
        margin-bottom: 1px;
      }
    `,
    link: css`
      ${theme.breakpoints.down('md')} {
        font-size: 0.8125rem;
      }
    `,
  };
};

export default useStyles;
