import { css, useTheme } from '@mui/material';
import { usePageGradientDisplay } from '../../../../app/ContextAPI';

const useStyles = () => {
  const theme = useTheme();
  const enableBackgroundGradient = usePageGradientDisplay();
  const gradientBackground = enableBackgroundGradient;

  return {
    stepIcon: css`
      margin-left: 15px;
      margin-right: 15px;
      height: 36px;
      margin-bottom: 3px;
      z-index: 14;
      background: ${gradientBackground
        ? theme.palette.primary[100]
        : theme.palette.grey[50]};
    `,
  };
};

export default useStyles;
