import { useTheme } from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';
import { css } from '@emotion/react';

const useStyles = () => {
  const theme = useTheme();

  return {
    root: css`
      height: 100vh;
      // background-color: ${theme.palette.background.default};
      color: ${theme.palette.text.primary};
    `,
    // Add more styles as needed
  };
};

export default useStyles;
