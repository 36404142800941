import Grid from '@mui/material/Grid';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Page from '../../components/Page';
import LoadingSpinner from '../LoadingSpinner';
import useStyles from './useStyles';

const Layout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      id="piral-core-layout"
      css={classes.root}
      direction="column"
      wrap="nowrap"
    >
      <Header />
      <LoadingSpinner />
      <Page>{children}</Page>
      <Footer />
    </Grid>
  );
};

export default Layout;
