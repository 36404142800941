import LensIcon from '@mui/icons-material/Lens';
import LensOutlinedIcon from '@mui/icons-material/LensOutlined';
import type { StepIconProps } from '@mui/material/StepIcon';
import CircularProgress from './CircularProgress';
import useStyles from './useStyles';

const StepIcon: React.FC<StepIconProps> = ({ active, completed }) => {
  const classes = useStyles();

  if (active) {
    return <CircularProgress />;
  }
  if (completed) {
    return <LensIcon css={classes.stepIcon} color="primary" />;
  }
  return <LensOutlinedIcon css={classes.stepIcon} color="primary" />;
};

export default StepIcon;
