import { css } from '@emotion/react';
import { useTheme } from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';
import { usePageGradientDisplay } from '../../../app/ContextAPI';

const useStyles = () => {
  const theme = useTheme();
  const enableBackgroundGradient = usePageGradientDisplay();

  let stepPageNavigableLinkStyle = css`
    color: ${theme.palette.primary[400]};
  `;

  if (enableBackgroundGradient) {
    stepPageNavigableLinkStyle = css`
      color: ${theme.palette.primary.main};
    `;
  }

  return {
    root: css`
      background: transparent;
      ${theme.breakpoints.down('lg')} {
        padding: 32px 0px 24px 24px;
      }
      padding: 0px 0px 24px 24px;
    `,
    stepContent: css`
      border-left-width: 2px;
      border-left-color: ${theme.palette.primary.main};
      border-left-style: dashed;
      padding-left: 35px;
      margin-left: 30px;
      margin-top: 0px;
    `,
    endStepContent: css`
      border-left-width: 2px;
      border-left-color: ${theme.palette.primary.main};
      padding-left: 35px;
      margin-left: 30px;
      margin-top: 0px;
    `,
    stepPage: css`
      color: ${theme.palette.text.secondary};
      padding: 0px;
    `,
    stepPageLink: css`
      text-decoration: none;
    `,
    stepNavigableLink: css`
      color: ${theme.palette.primary.main};
    `,
    stepPageNavigableLink: stepPageNavigableLinkStyle,
    stepPageLinkActive: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
  };
};

export default useStyles;
