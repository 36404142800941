import { css } from '@emotion/react';
import { useTheme } from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';

const useStyles = () => {
  const theme = useTheme();

  return {
    root: css`
      background: transparent;
      width: 100%;
      padding: 14px;
      padding-bottom: 0px;
    `,
    stepPage: css`
      color: ${theme.palette.text.secondary};

      & .MuiStepLabel-alternativeLabel {
        margin-top: 0px;
      }
    `,
  };
};

export default useStyles;
