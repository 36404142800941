import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

const useLoadingSpinnerStyles = () => {
  const theme = useTheme();

  const styles = {
    root: css`
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;
      overflow: auto;
      pointer-events: none;

      &.closing {
        opacity: 0;
        transform: scale(0.25);
      }
    `,
    spinnerBackground: css`
      background-color: rgba(0, 0, 0, 0.4);
      position: fixed;
      z-index: 8000;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    `,
    '@keyframes loadingOpen': css`
      from {
        opacity: 0;
        transform: scale(0.25);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    `,
    '@keyframes loadingSpinnerSpin': css`
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    `,
    box: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 200px;
      background-color: ${theme.palette.grey[100]};
      padding: 40px 80px;
      border-radius: 2px;
      border: 1px solid #cfcfcf;
      pointer-events: all;
    `,
    headingMain: css`
      margin: 10px 0 0 8px;
      color: ${theme.palette.text.primary};
    `,
    top: css`
      opacity: 0.25;
    `,
    bottom: css`
      animation-duration: 0.75s;
      position: absolute;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-top: -80px;
        margin-left: -40px;
      }
    `,
    spinnerWidth: css`
      stroke-dasharray: 40px, 200px !important;
    `,
    backdrop: css`
      z-index: ${theme.zIndex.drawer + 1};
      color: ${theme.palette.common.white};
    `,
  };

  return styles;
};

export default useLoadingSpinnerStyles;
