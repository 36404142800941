import DisplayText from '@amfament/digitalsales-lib-coreuicomps/DisplayText';
import Grid from '@mui/material/Grid';
import type { ResourceConfig } from '../../app/types';
import useStyles from './useStyles';
import { content } from './content';

const { title, subTitle } = content;
declare let CONFIG: ResourceConfig;
const generalHardstopImage = `${CONFIG.image.baseURL}hardstop-general.png`;

const HardStop: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" css={classes.root} spacing={2}>
      <Grid item xs={12} md={8}>
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <Grid item xs={12} lg={6}>
            <img
              css={classes.image}
              src={generalHardstopImage}
              alt="Exception"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <div css={classes.titleContainer}>
              <DisplayText variant="h1" css={classes.title}>
                {title}
              </DisplayText>
              <DisplayText css={classes.subtitle}>{subTitle}</DisplayText>
            </div>
          </Grid>
        </Grid>
        <div css={classes.footerSeparation} />
      </Grid>
    </Grid>
  );
};

export default HardStop;
