import { css } from '@emotion/react';

const useStyles = () => {
  return {
    stepIcon: css`
      margin-left: 19px;
      margin-right: 15px;
    `,
  };
};

export default useStyles;
