import { css } from '@emotion/react';
import { useTheme } from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';
import { usePageGradientDisplay } from '../../../app/ContextAPI';

const useStyles = () => {
  const theme = useTheme();
  const enableBackgroundGradient = usePageGradientDisplay();
  const gradientBackground = enableBackgroundGradient;

  return {
    appBarRoot: css`
      min-height: 64px;
      overflow: hidden;
      background: ${gradientBackground
        ? theme.palette.primary[100]
        : theme.palette.background.altNeutral};
      border-bottom: ${gradientBackground
        ? 'none'
        : `1px solid ${theme.palette.divider}`};
      width: 100%;
    `,
    root: css`
      min-height: 64px;
      background: transparent;
      box-shadow: none;
      @media print {
        display: none;
      }
      z-index: 3;
      top: 0;
      width: 100%;
    `,
    currentStep: css`
      color: ${theme.palette.primary.main};
      padding-bottom: 12px;
      overflow-wrap: break-word;
      max-width: 90%;
    `,
    pageName: css`
      font-weight: ${theme.typography.fontWeightRegular};
    `,
  };
};

export default useStyles;
