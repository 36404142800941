import { css } from '@emotion/react';
import { useTheme } from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';

const useStyles = () => {
  const theme = useTheme();

  return {
    root: css`
      width: 305px;
      margin-top: -15px;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
      border-top: 1px solid #adadad;
      background-color: ${theme.palette.background.default};

      ${theme.breakpoints.down('md')} {
        margin-top: 0px;
      }
    `,
    arrow: css`
      width: 20px;
      height: 20px;
      border-left: 1px solid #adadad;
      border-top: 1px solid #adadad;
      border-bottom-right-radius: 100%;
      transform: rotate(45deg);
      position: absolute;
      background: ${theme.palette.background.default};
      top: -10px;
      margin-top: -15px;
      left: 89%;

      ${theme.breakpoints.down('xl')} {
        left: 87.5%;
      }
      ${theme.breakpoints.down('md')} {
        left: 89%;
        margin-top: 0px;
      }
      ${theme.breakpoints.down('sm')} {
        left: 87.5%;
      }
    `,
    grayArrow: css`
      background: ${theme.palette.grey[100]};
      width: 20px;
      height: 20px;
      border-left: 1px solid #adadad;
      border-top: 1px solid #adadad;
      border-bottom-right-radius: 100%;
      transform: rotate(45deg);
      position: absolute;
      top: -10px;
      margin-top: -15px;
      left: 89%;

      ${theme.breakpoints.down('xl')} {
        left: 87.5%;
      }
      ${theme.breakpoints.down('md')} {
        left: 89%;
        margin-top: 0px;
      }
      ${theme.breakpoints.down('sm')} {
        left: 87.5%;
      }
    `,
  };
};

export default useStyles;
