import { css } from '@emotion/react';
import { useTheme } from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';

export const size = 38;

const useStyles = () => {
  const theme = useTheme();

  return {
    outerCircle: css`
      margin-left: -${size}px;
    `,
    percentageTextSize: css`
      font-size: 0.875rem !important;
    `,
    percentageSignSize: css`
      font-size: 0.625rem !important;
    `,
    hideStepConnectorColor: css`
      background-color: ${theme.palette.common.white};
      border-radius: 100%;
    `,
  };
};

export default useStyles;
