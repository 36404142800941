import { css } from '@emotion/react';
import { useTheme } from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';

const useStyles = () => {
  const theme = useTheme();

  return {
    root: css`
      padding-top: 15px;
      padding-bottom: 15px;
      margin-left: 20px;
    `,
    border: css`
      border-left: 1px solid #adadad;
      border-right: 1px solid #adadad;
      border-bottom: 1px solid #adadad;
    `,
    title: css`
      margin-bottom: 10px;
    `,
    container: css`
      line-height: 1;
    `,
    value: css`
      font-weight: ${theme.typography.fontWeightBold};
      margin-bottom: 20px;
    `,
  };
};

export default useStyles;
