import { type Theme, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import {
  useFullPageDisplay,
  usePageGradientDisplay,
} from '../../app/ContextAPI';
import ProgressStepper from '../ProgressStepper';
import useStyles from './useStyles';
import RightPanelControl from '../RightPanelControl';

const Page: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const classes = useStyles();
  const fullPageDisplay = useFullPageDisplay();
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('lg'),
  );

  const enableBackgroundGradient = usePageGradientDisplay();
  return (
    <>
      {enableBackgroundGradient && (
        <Grid container css={classes.gradient} item xs={12} />
      )}
      <Grid
        container
        item
        css={
          fullPageDisplay && isMobile
            ? classes.mobileAppBarDisabled
            : classes.rootContainer
        }
      >
        {!fullPageDisplay && (
          <Grid container item lg={2} xs={12}>
            <ProgressStepper />
          </Grid>
        )}
        <Grid
          container
          css={classes.root}
          item
          lg={fullPageDisplay ? 12 : 8}
          xs={12}
        >
          {children}
        </Grid>
        <RightPanelControl />
      </Grid>
    </>
  );
};

export default Page;
