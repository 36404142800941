import { css } from '@emotion/react';
import { useTheme } from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';
import { useFullPageDisplay } from '../../app/ContextAPI';

const useStyles = () => {
  const theme = useTheme();
  const fullPageDisplay = useFullPageDisplay();

  return {
    gradient: css`
      position: absolute;
      z-index: -100;
      background-image: linear-gradient(
        ${theme.palette.primary[100]} 13%,
        ${theme.palette.common.white} 26%
      );
      top: 123px;
      min-height: 100%;
      ${theme.breakpoints.down('md')} {
        top: ${fullPageDisplay ? '60px' : '123px'};
      }
    `,
    root: css`
      min-height: calc(100% - 352px);
      ${theme.breakpoints.down('lg')} {
        min-height: calc(100% - 585px);
      }
    `,
    rootContainer: css`
      padding-top: 4rem;
      padding-bottom: 6rem;
      ${theme.breakpoints.down('lg')} {
        padding-top: 0px;
      }
    `,
    mobileAppBarDisabled: css`
      padding-top: 2rem;
      padding-bottom: 6rem;
    `,
  };
};

export default useStyles;
