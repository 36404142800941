import { css } from '@emotion/react';
import { useTheme } from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';

const useStyles = () => {
  const theme = useTheme();

  return {
    root: css`
      height: 85px;
      border: 1px solid #adadad;
      border-top: none;
      cursor: pointer;

      &:hover {
        background-color: ${theme.palette.grey[100]};

        & > .helpIcon {
          color: ${theme.palette.secondary.main};
        }

        & > .helpLink {
          font-weight: ${theme.typography.fontWeightBold};
        }
      }

      &:focus {
        background-color: ${theme.palette.grey[100]};

        & > .helpIcon {
          color: ${theme.palette.secondary.main};
        }

        & > .helpLink {
          font-weight: ${theme.typography.fontWeightBold};
        }
      }
    `,
    helpIcon: css`
      margin-top: 24px;
      margin-left: 20px;
    `,
    text: css`
      margin-top: 15px;
      margin-left: -35px;
    `,
    helpLink: css`
      color: ${theme.palette.primary.main};
      font-weight: ${theme.typography.fontWeightMedium};
    `,
  };
};

export default useStyles;
