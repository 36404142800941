import CallCenterBox from '@amfament/digitalsales-lib-common/CallCenterBox';
import { useTheme, Grid } from '@mui/material';
import { useState } from 'react';
import YourAgent from '@amfament/digitalsales-lib-common/YourAgent';
import {
  useDisplayCallCenterBox,
  useFullPageDisplay,
  useLockCallCenterBox,
  useYourAgentData,
} from '../../app/ContextAPI';
import useStyles from './useStyles';
import { typesOfAgents } from './constants';
import { useManageScrolling } from './management';

const RightPanelControl: React.FC = () => {
  const classes = useStyles();
  const displayCallCenterBox = useDisplayCallCenterBox();
  const lockCallCenterBox = useLockCallCenterBox();
  const agent = useYourAgentData();
  const fullPageDisplay = useFullPageDisplay();

  // Determine if we display the agent card or not depending on the source
  const displayAgent = agent && typesOfAgents.includes(agent!.source);
  const [shouldFix, setShouldFix] = useState(false);
  const customTheme = useTheme();

  const rootOverride = useManageScrolling(setShouldFix, customTheme);

  return (
    <Grid
      container
      item
      lg={2}
      xs={12}
      css={[
        lockCallCenterBox || (!shouldFix && classes.locked),
        !lockCallCenterBox && shouldFix && classes.fixed,
      ]}
    >
      {displayAgent ? (
        <Grid item xs={12}>
          <div css={classes.cardStyling}>
            <YourAgent
              agent={agent}
              hideInfo={{
                hideChangeAgent: true,
                hideAgencyAddress: true,
                hideAgentWebsite: true,
              }}
            />
          </div>
        </Grid>
      ) : (
        displayCallCenterBox &&
        !fullPageDisplay && (
          <Grid item xs={12} css={classes.callCenterBox}>
            <CallCenterBox style={rootOverride} />
          </Grid>
        )
      )}
    </Grid>
  );
};

export default RightPanelControl;
